<template>
  <div>
    <h1>Это тест блока #{{blockId}}</h1>
    <p v-if="component">{{component}}</p>
    <TaskComponentsParser v-if="component"
        class="slidehtml"
        :slide="1"
        :slidecomponents="[component]"
        :host="false"
        view="lesson"
        source="light"
        @componentSolved="componentSolved(component)"
        :lightCourseComponentIndex="1"
        :key="`parser-for-slide-1`"/>
  </div>
</template>

<script>
import { PreviewService } from "../../../services/api.service";
import TaskComponentsParser from "../../parts/class/preview/TaskComponentsParser.vue";
import {tasksWithoutSolving} from "../../../dictionaries/tasksWithoutSolving";

export default {
  name: "PreviewMaterialBlock",
  components: {
    TaskComponentsParser,
  },
  data() {
    return {
      component: null,
      wnr: [],
    }
  },
  async mounted() {
    await this.fetchBlockById()
  },
  computed: {
    blockId() {
      return this.$route.params.blockId
    },
  },
  methods: {
    async fetchBlockById() {
      let res = await PreviewService.getBlockById(this.blockId)
      this.component = res?.data?.data
    },
    async fetchLessonBlockById() {
      let res = await PreviewService.getLessonBlockById(this.lessonId, this.blockId)
      this.component = res?.data?.data
    },
    componentSolved(component, index, finished, wnr) {
      if(!finished) return
      const componentCode = component?.component_type?.code || ''
      this.wnr = wnr
      this.justNext = false
      if(tasksWithoutSolving.includes(componentCode)) {
        this.lightCourseComponentIndex = index + 1
        if((index + 1) === this.currentSlide.components.length) {
          this.justNext = true
          this.showResult = true
        }
      } else {
        this.currentTaskDeductsLives = component?.light_lives || false
        this.showResult = true
      }
    },
  }
}
</script>

<style scoped lang="scss">

</style>